import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../store'
import { useState, useEffect } from 'react'
import api from '../helpers/api'
import { APIResponse } from '../@types/api'
import { toast } from 'react-toastify'
import { format } from '../helpers/number'
import { Button, Col, Row, Tooltip, Spin, Skeleton, Flex, Space, Typography } from 'antd'
import dayjs from 'dayjs'
import { requestUser } from '../store/user/user.actions'

const { Text } = Typography

const Rolling = () => {
    const dispatch = useDispatch()
    const prefixState = useSelector((state: ApplicationState) => state.prefix)
    const [dataRolling, setDataRolling] = useState<any>()
    const [loadingRolling, setLoadingRolling] = useState<boolean>(true)
    const [loadingWithdrawRolling, setLoadingWithdrawRolling] = useState<boolean>(false)

    const getRollingBalance = async () => {
        setLoadingRolling(true)
        try {
            const res: any = await api.get<void, APIResponse>('/member/rolling/balance')
            setDataRolling(res)
        } catch ({ message }: any) {
            toast.error(message as string)
        }
        setLoadingRolling(false)
    }

    const withdrawRolling = async () => {
        setLoadingWithdrawRolling(true)
        try {
            const res: any = await api.post<void, APIResponse>('/member/rolling/withdraw')
            dispatch(requestUser())
            getRollingBalance()
            toast.success(res.message as string)
        } catch ({ message }: any) {
            toast.error(message as string)
        }
        setLoadingWithdrawRolling(false)
    }

    useEffect(() => {
        getRollingBalance()
    }, [])

    // prefixState?.info?.termandcondition?.rollingDescription
    return (
        <>
            <Row justify="center" className="margin-form-card">
                <Col xs={24} md={16} xl={12}>
                    <div className="form-card">
                        <div className="form-card-body-checkin" >
                            <div className='rolling-balance'>
                                <div>
                                    <span>ยอดที่คุณจะได้รับ</span>
                                    <Tooltip placement="right" title={'อัพเดทยอด'} color='#121416'>
                                        <i className="fa-sharp fa-regular fa-arrows-rotate" style={{ fontSize: 18, marginLeft: 8 }} onClick={getRollingBalance}></i>
                                    </Tooltip>
                                </div>
                                {/* <div>{dataRolling && format(dataRolling?.balance)}</div> */}
                                {!loadingRolling && dataRolling ?
                                    <>
                                        <div style={{ fontSize: 32 }}>
                                            <small>฿</small>  {format(dataRolling?.balance || 0)}
                                        </div>
                                        <div style={{ opacity: 0.65 }}><small>ยอดอัพเดทล่าสุด: {dataRolling.lastUpdate !== "0001-01-01 00:00:00" ? dayjs(dataRolling.lastUpdate).format("DD-MM-YYYY HH:mm:ss") : "-"}</small></div>
                                    </>
                                    : <Skeleton.Button active={true} shape={'default'} block={false} />
                                }
                                {/* <div><small>ยอดเทิร์นโอเวอร์ปัจุบัน: {dataRolling && format(dataRolling?.totalTurnover)} บาท</small></div> */}
                                {/* <div style={{ opacity: 0.65 }}><small>ยอดเทิร์นโอเวอร์ปัจจุบัน: ฿ 0</small></div> */}

                            </div>
                            <Button type="primary" block
                                onClick={withdrawRolling}
                                loading={loadingWithdrawRolling}
                                disabled={loadingWithdrawRolling}
                            >
                                รับคืนยอดแทง
                            </Button>
                            {!loadingRolling && dataRolling ?
                                <>
                                    {dataRolling.minWithdraw &&
                                        <div className='rolling-text-extra'>
                                            <small>ยอดขั้นต่ำ <span className='rolling-text-extra-span'>฿{format(dataRolling.minWithdraw || 0)}</span> ถึงจะสามารถกดรับเงินคืนได้</small>
                                        </div>
                                    }
                                    <div className='rolling-text-info'>
                                        <div className='rolling-text-info-item'>
                                            <div>{dataRolling.percentRate}%</div>
                                            <div style={{ opacity: 0.8 }}><small>% ของคอมมิชชั่น</small></div>
                                        </div>
                                        <div className='rolling-text-info-item'>
                                            <div>฿ {format(dataRolling.sumMonth || 0)}</div>
                                            <div style={{ opacity: 0.8 }}><small>ยอดเดือนนี้</small></div>
                                        </div>
                                        <div className='rolling-text-info-item'>
                                            <div>฿ {format(dataRolling.sumAll || 0)}</div>
                                            <div style={{ opacity: 0.8 }}><small>ยอดที่รับสะสม</small></div>
                                        </div>
                                    </div>
                                </> :
                                <Flex justify={"center"} align={'center'} style={{ marginTop: 20 }}>
                                    <Spin />
                                </Flex>
                            }
                            {prefixState?.info?.termandcondition?.rollingDescription &&
                                <div className="check-in-condition" >
                                    <p style={{ marginBottom: 10 }}><u>หมายเหตุ</u></p>
                                    <Space direction="vertical">
                                        {prefixState?.info?.termandcondition?.rollingDescription.split('\n').map((line, index) => <Text key={`termandcondition-${index}`} style={{ lineHeight: index === 0 ? "2" : "0", fontWeight: index === 0 ? 800 : 0 }}>{line}</Text>)}
                                    </Space>
                                </div>
                            }
                        </div>
                    </div>
                </Col>
            </Row>
            {/* <div style={{ color: '#fff' }}>
                <div>ยอดแทงที่คุณจะได้รับ</div>
                <div>{dataRolling && format(dataRolling?.balance)}</div>

                <div>ยอดเทิร์นโอเวอร์ปัจุบัน</div>
                <div>{dataRolling && format(dataRolling?.totalTurnover)}</div>

                <div>หมายเหตุ: {prefixState?.info?.termandcondition?.rollingDescription}</div>
            </div> */}
        </>
    )
}

export default Rolling